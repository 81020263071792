





























































import { updatePasswordApi } from '@/api/user'
import { showError } from '@/utils/common'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Form, FormItem } from 'element-ui'
import Main from '@/components/Main.vue'
import { resPass } from '@/utils/res'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
export default defineComponent({
    components: {
        Main,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)

        const formInfo = reactive({
            oldPassword: '',
            password: '',
            repeatPassword: ''
        })
        const formRef = ref()
        const requiresOldPassword = !(userInfo.value.passport_only || false)

        const formRules = {
            oldPassword: [{ required: requiresOldPassword, message: root.$i18n.t('passwordReset.formRule0'), trigger: ['blur', 'change'] }],
            password: [
                { required: true, message: root.$i18n.t('passwordReset.formRule1'), trigger: ['blur', 'change'] },
                { min: 8, message: root.$i18n.t('passwordReset.formRule3'), trigger: 'blur' },
                {
                    required: true,
                    validator: (rule: any[], value: string, callback: any) => {
                        if (!resPass.test(value)) {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule4')))
                        } else {
                            callback()
                        }
                    },
                    trigger: 'blur'
                }
            ],
            repeatPassword: [
                {
                    required: true,
                    validator: (rule: any[], value: string, callback: any) => {
                        if (value === '') {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule2')))
                        } else if (value !== formInfo.password) {
                            callback(new Error(<string>root.$i18n.t('passwordReset.formRule5')))
                        } else {
                            callback()
                        }
                    },
                    trigger: 'blur'
                }
            ]
        }

        const iconInfo = reactive({
            oldPassword: true,
            password: true,
            repeatPassword: true
        })

        const submitLoad = ref(false)
        const submitForm = () => {
            formRef.value
                .validate()
                .then(async () => {
                    submitLoad.value = true
                    try {
                        await updatePasswordApi({
                            password: formInfo.oldPassword,
                            new_password: formInfo.password
                        })
                        root.$message.success(<string>root.$i18n.t('passwordReset.successMsg1'))
                        userStore.logout()
                    } catch (error) {
                        showError(error)
                    }
                    submitLoad.value = false
                })
                .catch(() => { })
        }
        return {
            formInfo,
            formRules,
            formRef,
            submitLoad,
            submitForm,
            iconInfo,
            requiresOldPassword,
        }
    }
})
